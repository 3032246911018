<template>
  <div class="flex_blcok">
    <div class="form_wrapper">
      <div class="form_container">
        <div class="title_container">
          <h2>Bemor ma'lumotlari</h2>
        </div>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">Bemor IDsi:</div>
            <div class="boldText">{{ patient_id }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">Ismi:</div>
            <div class="boldText">{{ first_name }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">Familiyasi:</div>
            <div class="boldText">{{ last_name }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">Sharifi:</div>
            <div class="boldText">{{ middle_name }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">Jinsi:</div>
            <div class="boldText">{{ gender }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">Tug'ilgan sanasi:</div>
            <div class="boldText">{{ birth_date }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">Manzili:</div>
            <div class="boldText">{{ address }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">Telefon raqami:</div>
            <div class="boldText">{{ phone_number }}</div>
          </div>
        </a-card>
      </div>
    </div>
    <div class="form_wrapper">
      <div class="form_container">
        <div class="title_container">
          <h2>Tahlil natijalari</h2>
        </div>
        <div class="row clearfix">
          <div class="">
            <form>
              <div class="input_field">
                <!-- <span><a-icon type="user" theme="outlined" /></span> -->
                <input
                  class="inputField"
                  type="number"
                  name="ismi"
                  placeholder="Ko'krak qafasidagi og'riq turi"
                  v-model="cpt"
                  required
                />
              </div>
              <div class="input_field">
                <!-- <span><a-icon type="user" theme="outlined" /></span> -->
                <input
                  class="inputField"
                  type="number"
                  name="familiyasi"
                  placeholder="Qon bosimi (dam olish vaqtidagi)"
                  v-model="eia"
                  required
                />
              </div>
              <div class="input_field">
                <!-- <span><a-icon type="user" theme="outlined" /></span> -->
                <input
                  class="inputField"
                  type="number"
                  name="sharifi"
                  placeholder="Qondagi xolesterin miqdori (mg/dl)"
                  v-model="fbs"
                  required
                />
              </div>
              <div class="input_field">
                <!-- <span><a-icon type="calendar" theme="outlined" /></span> -->
                <input
                  class="inputField"
                  type="number"
                  name="sana"
                  placeholder="Qondagi qand miqdori"
                  v-model="mhra"
                  required
                />
              </div>
              <div class="input_field">
                <!-- <span><a-icon type="home" theme="outlined" /></span> -->
                <input
                  class="inputField"
                  type="number"
                  name="manzili"
                  placeholder="Elektrokardiografik natijalar"
                  v-model="nmvcf"
                  required
                />
              </div>
              <div class="input_field">
                <!-- <span><a-icon type="phone" theme="outlined" /></span> -->
                <input
                  class="inputField"
                  type="number"
                  name="telefon"
                  placeholder="Maksimal yurak urish tezligi"
                  v-model="rbp"
                  required
                />
              </div>
              <div class="input_field">
                <!-- <span><a-icon type="phone" theme="outlined" /></span> -->
                <input
                  class="inputField"
                  type="number"
                  name="telefon"
                  placeholder="Jismoni mashqlar bilan bog'liq angina"
                  v-model="rer"
                  required
                />
              </div>
              <div class="input_field">
                <!-- <span><a-icon type="phone" theme="outlined" /></span> -->
                <input
                  class="inputField"
                  type="number"
                  name="telefon"
                  placeholder="ST depressiyasi"
                  v-model="sc"
                  required
                />
              </div>
              <div class="input_field">
                <!-- <span><a-icon type="phone" theme="outlined" /></span> -->
                <input
                  class="inputField"
                  type="number"
                  name="telefon"
                  placeholder="Mashqlar vaqtidagi ST segmentining qiyaligi"
                  v-model="spests"
                  required
                />
              </div>
              <div class="input_field">
                <!-- <span><a-icon type="phone" theme="outlined" /></span> -->
                <input
                  class="inputField"
                  type="number"
                  name="telefon"
                  placeholder="Flourosopy tomonidan ranglangan asosiy tomirlar soni"
                  v-model="stdierr"
                  required
                />
              </div>
              <div class="input_field">
                <!-- <span><a-icon type="phone" theme="outlined" /></span> -->
                <input
                  class="inputField"
                  type="number"
                  name="telefon"
                  placeholder="THAL"
                  v-model="thal"
                  required
                />
              </div>
            </form>
            <button class="add_btn" @click="goToMain()">Saqlash</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/index";
import axios from "@/axios.js";
export default {
  data() {
    return {
      analysis_id: this.$route.query.analysis_id,
      patient_id: null,
      first_name: null,
      last_name: null,
      middle_name: null,
      birth_date: null,
      address: null,
      phone_number: null,
      gender: null,
      registrationId: null,
      cpt: null,
      eia: null,
      fbs: null,
      mhra: null,
      nmvcf: null,
      rbp: null,
      rer: null,
      sc: null,
      spests: null,
      stdierr: null,
      thal: null,
    };
  },
  mounted() {
    axios.get("/api/analysis/" + this.$route.query.analysis_id).then((res) => {
      this.patient_id = res.data.patientId;
      axios.get("/api/patients/" + this.patient_id).then((res) => {
        this.first_name = res.data.firstName;
        this.last_name = res.data.lastName;
        this.middle_name = res.data.middleName;
        this.birth_date = res.data.birthDate;
        this.address = res.data.address;
        this.phone_number = res.data.phoneNumber;
        this.gender = res.data.gender == 1 ? "ERKAK" : "AYOL";
        this.registrationId = res.data.registrationId;
      });
    });
  },
  methods: {
    setGender(val) {
      this.gender = val;
    },
    goToMain() {
      if (
        this.cpt &&
        this.eia &&
        this.fbs &&
        this.mhra &&
        this.nmvcf &&
        this.rbp &&
        this.rer &&
        this.sc &&
        this.spests &&
        this.stdierr &&
        this.thal
      ) {
        axios
          .patch(`/api/analysis/${this.analysis_id}`, {
            cpt: this.cpt,
            eia: this.eia,
            fbs: this.fbs,
            mhra: this.mhra,
            nmvcf: this.nmvcf,
            rbp: this.rbp,
            rer: this.rer,
            sc: this.sc,
            spests: this.spests,
            stdierr: this.stdierr,
            thal: this.thal,
            patientId: this.patient_id,
            laboratoryId: localStorage.getItem("role_id"),
          })
          .then(() => {
            // console.log(res);
            // let userLink = res.data._links.self.href;
            // let analysis_id = userLink.substring(
            //   userLink.lastIndexOf("/") + 1,
            //   userLink.length
            // );
            axios
              .post("/api/laboratories/submit", {
                patientId: Number(this.patient_id),
                analysisId: Number(this.analysis_id),
              })
              .then(() => {
                router.replace("/laboratory");
                this.$toast.success("Laboratoriya natijalari qo'shildi !", {
                  position: "top-right",
                  timeout: 5000,
                  draggablePercent: 0.6,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                });
              })
              .catch(() => {
                this.$toast.error("Maydonlarni to'g'ri to'ldiring !", {
                  position: "top-right",
                  timeout: 5000,
                  draggablePercent: 0.6,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                });
              });
          })
          .catch(() => {
            this.$toast.error("Maydonlarni to'g'ri to'ldiring !", {
              position: "top-right",
              timeout: 5000,
              draggablePercent: 0.6,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            });
          });
        // });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$yellow: #1890ff;
$black: #000000;
$grey: #1890ff;

.boldText {
  font-weight: 600;
  color: black;
}
.flex_blcok {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.flex_cards {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.add_btn {
  background: $yellow;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 1.1em;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: darken($yellow, 7%);
  }
  &:focus {
    background: darken($yellow, 7%);
  }
}
.inputField {
  display: block;
  width: 100%;
  height: 36px;
  padding: 0.375rem 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-family: "Montserrat";
}
.clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
  }
}
.form_wrapper {
  background: #fff;
  width: 450px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 25px;
  margin: 3% auto 0;
  position: relative;
  z-index: 1;
  border-top: 5px solid $yellow;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: none;
  transition: none;
  -webkit-animation: expand 0.8s 0.6s ease-out forwards;
  animation: expand 0.8s 0.6s ease-out forwards;
  opacity: 0;
  h2 {
    font-size: 1.5em;
    line-height: 1.5em;
    margin: 0;
  }
  .title_container {
    text-align: center;
    padding-bottom: 15px;
  }
  h3 {
    font-size: 1.1em;
    font-weight: normal;
    line-height: 1.5em;
    margin: 0;
  }
  label {
    font-size: 12px;
  }
  .row {
    margin: 10px -15px;
    > div {
      padding: 0 15px;
      box-sizing: border-box;
    }
  }
  .col_half {
    width: 50%;
    float: left;
  }
  .input_field {
    position: relative;
    margin-bottom: 20px;
    -webkit-animation: bounce 0.6s ease-out;
    animation: bounce 0.6s ease-out;
    > span {
      position: absolute;
      left: 0;
      top: 0;
      color: #333;
      height: 100%;
      border-right: 1px solid $grey;
      text-align: center;
      width: 30px;
      > i {
        padding-top: 10px;
      }
    }
  }
  .textarea_field {
    > span {
      > i {
        padding-top: 10px;
      }
    }
  }
  input {
    &[type="text"],
    &[type="date"],
    &[type="email"],
    &[type="number"] {
      width: 100%;
      padding: 8px 10px 9px 10px;
      height: 35px;
      border: 1px solid $grey;
      box-sizing: border-box;
      outline: none;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    &[type="text"]:hover,
    &[type="date"]:hover,
    &[type="number"]:hover {
      background: #fafafa;
    }
    &[type="text"]:focus,
    &[type="number"]:focus,
    &[type="date"]:focus {
      -webkit-box-shadow: 0 0 2px 1px #1890ff;
      -moz-box-shadow: 0 0 2px 1px #1890ff;
      box-shadow: 0 0 2px 1px #1890ff;
      border: 1px solid $yellow;
      background: #fafafa;
    }
    &[type="submit"] {
      background: $yellow;
      height: 35px;
      line-height: 35px;
      border-radius: 5px;
      width: 100%;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
      font-size: 1.1em;
      margin-bottom: 10px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: darken($yellow, 7%);
      }
      &:focus {
        background: darken($yellow, 7%);
      }
    }
    &[type="checkbox"],
    &[type="radio"] {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
}
.form_container {
  .row {
    .col_half.last {
      border-left: 1px solid $grey;
    }
  }
}
.checkbox_option {
  label {
    margin-right: 1em;
    position: relative;
    &:before {
      content: "";
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      margin-right: 0.5em;
      vertical-align: -2px;
      border: 2px solid $grey;
      padding: 0.12em;
      background-color: transparent;
      background-clip: content-box;
      transition: all 0.2s ease;
    }
    &:after {
      border-right: 2px solid black;
      border-top: 2px solid black;
      content: "";
      height: 20px;
      left: 2px;
      position: absolute;
      top: 7px;
      transform: scaleX(-1) rotate(135deg);
      transform-origin: left top;
      width: 7px;
      display: none;
    }
  }
  input {
    &:hover + label:before {
      border-color: black;
    }
    &:checked + label {
      &:before {
        border-color: black;
      }
      &:after {
        -moz-animation: check 0.8s ease 0s running;
        -webkit-animation: check 0.8s ease 0s running;
        animation: check 0.8s ease 0s running;
        display: block;
        width: 7px;
        height: 20px;
        border-color: black;
      }
    }
  }
}
.radio_option {
  label {
    margin-right: 1em;
    &:before {
      content: "";
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      margin-right: 0.5em;
      border-radius: 100%;
      vertical-align: -3px;
      border: 2px solid $grey;
      padding: 0.15em;
      background-color: transparent;
      background-clip: content-box;
      transition: all 0.2s ease;
    }
  }
  input {
    &:hover + label:before {
      border-color: black;
    }
    &:checked + label:before {
      background-color: black;
      border-color: black;
    }
  }
}
.select_option {
  position: relative;
  width: 100%;
  select {
    display: inline-block;
    width: 100%;
    height: 35px;
    padding: 0px 15px;
    cursor: pointer;
    color: #7b7b7b;
    border: 1px solid $grey;
    border-radius: 0;
    background: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.2s ease;
    &::-ms-expand {
      display: none;
    }
    &:hover,
    &:focus {
      color: black;
      background: #fafafa;
      border-color: black;
      outline: none;
    }
  }
}
.select_arrow {
  position: absolute;
  top: calc(50% - 4px);
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-width: 8px 5px 0 5px;
  border-style: solid;
  border-color: #7b7b7b transparent transparent transparent;
}

.select_option select {
  &:hover + .select_arrow,
  &:focus + .select_arrow {
    border-top-color: black;
  }
}
.credit {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 15px;
  color: $yellow;
  a {
    color: darken($yellow, 7%);
  }
}
@-webkit-keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 7px;
  }
  50% {
    height: 20px;
    width: 7px;
  }
}

@keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 7px;
  }
  50% {
    height: 20px;
    width: 7px;
  }
}

@-webkit-keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
  }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
    transform: scale3d(1, 1.2, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
    transform: scale3d(1, 0.85, 1);
  }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
    transform: scale3d(1, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
    transform: translate3d(0, -6px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@media (max-width: 600px) {
  .form_wrapper {
    .col_half {
      width: 100%;
      float: none;
    }
  }
  .bottom_row {
    .col_half {
      width: 50%;
      float: left;
    }
  }
  .form_container {
    .row {
      .col_half.last {
        border-left: none;
      }
    }
  }
  .remember_me {
    padding-bottom: 20px;
  }
}
</style>