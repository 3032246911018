import axios from "axios";
import router from "@/router/index";
// import router from "./router";
const baseURL = 'https://api.cardiodiag.uz/';
axios.defaults.headers.common[
    "Authorization"
] = `Bearer ${localStorage.getItem("access_token")}`;
const instance = axios.create({
    baseURL,
});

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            console.log(error.response.status);
            localStorage.removeItem("access_token");
            localStorage.removeItem("user_id");
            localStorage.removeItem("user_name");
            localStorage.removeItem("user_role");
            router.replace("/sign-in");
        }
        return Promise.reject(error);
    }
);
export default instance;